// noinspection DuplicatedCode

import React, { Fragment } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { Grid, Typography } from '@material-ui/core'

import useStyles from './styles'
import { SectionContainer, SectionTitle } from '../../components/_layout'
import { GatsbyImage } from 'gatsby-plugin-image'
import { SocialShareIcons } from '../../components/_common'


const getData = graphql`
    query {
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`

const Post = ( { post } ) => {
  const { slug, titulo, categoria: { categoria }, fechaDeCreacion, imagenPrincipal, contenido } = post
  const { site } = useStaticQuery( getData )
  const { siteUrl } = site.siteMetadata

  const contenidoBodyJSON = JSON.parse( contenido.raw )

  const styles = useStyles()

  const options = {
    renderText: ( text ) =>
        text.split( '\n' ).flatMap( ( text, i ) => [ i > 0 && <br key={ i }/>, text ] ),
    renderNode: {
      'embedded-asset-block': ( node ) => {
        return (
            <div>
              { node.data.target.fields && (
                  <img
                      width="80%"
                      src={ node.data.target.fields.file['en-US'].url }
                      alt={ titulo }
                  />
              ) }
            </div>
        )
      }
    }
  }

  return (
      <SectionContainer sectionID={ `Evento: ${ titulo }` }>


        <Grid container className={ styles.wrapper }>
          <Grid item container className={ styles.imgContainer }>
            <GatsbyImage
                alt={ 'imagen galeria' }
                image={ imagenPrincipal.gatsbyImageData }
                className={ styles.postMainImage }/>
          </Grid>

          <SectionTitle title={ titulo }/>

          <Grid container className={ styles.infoWrapper }>
            <Grid container item xs className={ styles.infoClass }>
              <Grid item xs>
                <Typography noWrap component="span">
                  Fecha de publicación - { fechaDeCreacion }
                </Typography>
              </Grid>

              <Grid item xs>
                <Typography noWrap component="span">
                  #{ categoria }
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container className={ styles.content }>
            <article>
              { documentToReactComponents( contenidoBodyJSON, options ) }
            </article>
          </Grid>
        </Grid>

        <SocialShareIcons
            text="Comparte este evento con tus amigos."
            shareTitle={ `${ titulo } con fecha: ${ fechaDeCreacion }` }
            url={ `${ siteUrl }/blog/${ slug }` }
        />
      </SectionContainer>
  )
}

export default Post
