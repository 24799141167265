import { makeStyles } from '@material-ui/core'


export default makeStyles( ( theme ) => ({
  componentRoot: {
    [theme.breakpoints.up( 'md' )]: {}
  },

  infoWrapper: {
    justifyContent: 'space-between',
    padding: theme.spacing(1, 0),
  },

  infoClass: {
    flexShrink: 1,
    flexGrow: 0,
    '& span': {
      fontSize: theme.typography.body2.fontSize,
      color: theme.palette.text.disabled,

      [theme.breakpoints.up('sm')]: {
        fontSize: theme.typography.body1.fontSize,
      },
    },
  },

  wrapper: {

  },

  imgContainer: {
    width: '100%',
  },

  postMainImage: {
    width: '100%',
    borderRadius: theme.roundnessSmall
  },

  content: {
    padding: theme.spacing(4, 0),
    color: theme.palette.text.main,
    fontSize: theme.typography.body1.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8, 0),
    },
  },
}) )