import React from 'react'
import { graphql } from 'gatsby'
import { Layout } from '../../components/_layout'
import { Galeria } from '../../components/Nosotros'
import { Grid } from '@material-ui/core'
import { Button } from '../../components/_common'
import theme from '../../App.theme'
import Post from './Post'



const PostTemplate = ( { data } ) => {
  const { post } = data

  return (
      <Layout withNav seoTitle={ post.titulo } seoDescription={ post.resumen }>
        <Grid style={ { height: 64, width: '100%' } }/>
        <Post { ...{ post } } />
        <Galeria imagenes={ post.galeria || [] }/>
        <Grid container justifyContent={ 'center' } style={ { margin: theme.spacing( 2, 0 ) } }>
          <Button
              aria={ 'Regresar al blog' }
              small
              goTo={ '/blog' }>
            Regresar al blog
          </Button>
        </Grid>
      </Layout>
  )
}

export default PostTemplate

export const query = graphql`
    query($slug: String!) {
        post: contentfulBlogPost(slug: { eq: $slug }) {
            slug
            titulo
            categoria {
                categoria
            }
            resumen
            fechaDeCreacion(formatString: "DD.MM.YY")
            contenido {
                raw
            }
            imagenPrincipal {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
            galeria {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
    }
`